<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_1.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>国际货运代理</h1>
                    <div class="articleDetail">
                        <p>作为第一批蓉欧班列的订舱货运代理公司，公司服务主要依托于成都保税区及西南地区联动，业务范围涵盖国际（国内）集装箱运输，报关，租箱，仓储、装箱等全方位领域，也包括非保税区的普通国际货运业务。</p>
                        <img src="@/assets/img_service_text_1.png" alt="">
                        <p>产品必须超越用户期望，它们在技术和战略上必须是切实可行的，它们必须在正确的时间以正确的方式投入市场。青蛙设计通过市场调研、创意整合、市场策略、市场预期、技术规划及成本管理等手段来帮助企业扩充自己的业务及目标。 </p>
                        <p>一旦锁定了机会点，我们会继续考察它们的主要功能与特点，以确保它们在符合成本效益及技术上具有可拓展性的同时满足客户的品牌与业务需求。接下来我们罗列出潜在合作机会、定价方案以及部署方案，将最具创新的设计落地投入市场。成长策略咨询服务帮助客户洞察先机并将它们转化为极具市场价值的产品及服务，以不断增加企业含金量。</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_1_1.png" alt=""><p>整柜订舱</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_1_2.png" alt=""><p>散柜拼箱</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_1_3.png" alt=""><p>跨境中转</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_1_4.png" alt=""><p>国际运输</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_1_5.png" alt=""><p>方案定制</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "servicedetail",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "servicedetail.css";
</style>
